import {
  Badge,
  Button,
  createTableColumn,
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  makeStyles,
  Spinner,
  TableCellLayout,
  TableColumnDefinition,
  Text,
  tokens
} from '@fluentui/react-components';
import {DeviceInfo} from '../../types';
import {DeviceModelImage} from '../../components/DeviceModelImage';
import {
  AllDevicesDocument,
  AxisCamera,
  DeviceOnboardingState,
  RemoveAxisDeviceDocument
} from '../../api/__generated__/graphql';
import useToasts from '../../hooks/useToasts';
import {useAppSelector} from '../../store/hooks';
import {usePopulatedTopbarValues} from '@axteams-one/populated-topbar';
import {useMutation, useQuery} from 'urql';
import {useState} from 'react';

const useStyles = makeStyles({
  table: {
    width: '100%',
    display: 'flex',
    paddingTop: tokens.spacingVerticalXL,
    paddingRight: tokens.spacingHorizontalXXL,
    paddingBottom: tokens.spacingVerticalS,
    flexDirection: 'column',
    overflowY: 'auto',
    scrollbarWidth: 'thin'
  },
  cameraModel: {
    display: 'flex',
    alignItems: 'center',
    columnGap: tokens.spacingHorizontalM
  },
  cameraNameModel: {
    display: 'flex',
    justifyContent: 'center',
    rowGap: tokens.spacingVerticalXXS,
    flexDirection: 'column'
  },
  cameraModelText: {
    color: tokens.colorNeutralForeground3
  }
});

const toHumanReadable = (text: string) => {
  text = text.toLowerCase().replaceAll('_', ' ');
  return text.charAt(0).toUpperCase() + String(text).slice(1);
};

const getBadge = (state: DeviceOnboardingState) => {
  return (
    <Badge
      appearance="tint"
      color={state === DeviceOnboardingState.Error ? 'danger' : 'informative'}
      shape="rounded"
    >
      {toHumanReadable(state)}
    </Badge>
  );
};

export const DeviceTable = () => {
  const styles = useStyles();
  const {organization} = usePopulatedTopbarValues();
  const {selectedResourceGroup} = useAppSelector(state => state.resourceGroupsSlice);
  const {showSuccessToast, showErrorToast} = useToasts();
  const [, offboardDevice] = useMutation(RemoveAxisDeviceDocument);
  const [{data}] = useQuery({
    pause: !organization,
    query: AllDevicesDocument,
    variables: {organizationArn: organization?.arn || ''}
  });
  const allDevices = data?.organization?.allDevices?.devices;
  const [fetching, setFetching] = useState<string[]>([]);

  if (!allDevices) {
    return <Spinner />;
  }

  const onClickOffboard = (camera: AxisCamera) => {
    setFetching(fetching => [...fetching, camera.serial]);
    offboardDevice({
      input: {
        deviceArn: camera.arn,
        retainIdentity: false
      }
    })
      .then(result => {
        const name = camera.attributes?.name ? camera.attributes.name : camera.serial;
        if (result.error) {
          showErrorToast(`Failed to offboard "${name}": ${result.error.message}`);
        } else {
          showSuccessToast('Offboarded ' + name);
        }
      })
      .finally(() => setFetching(fetching => fetching.filter(serial => serial !== camera.serial)));
  };

  const columns: TableColumnDefinition<AxisCamera>[] = [
    createTableColumn<AxisCamera>({
      renderHeaderCell: () => 'Camera model / name',
      renderCell: camera => (
        <TableCellLayout>
          <div className={styles.cameraModel}>
            <DeviceModelImage piaId={camera.model?.piaItemId || undefined} />
            <div className={styles.cameraNameModel}>
              <Text weight="semibold">
                {camera.attributes?.name ? camera.attributes.name : camera.model?.name}
              </Text>
              <Text className={styles.cameraModelText}>
                {camera.attributes?.name ? camera.model?.name : undefined}
              </Text>
            </div>
          </div>
        </TableCellLayout>
      ),
      columnId: 'model',
      compare: (a, b) => (a.model?.name || '').localeCompare(b.model?.name || '')
    }),
    createTableColumn<AxisCamera>({
      renderHeaderCell: () => 'Onboarding status',
      renderCell: camera => getBadge(camera.onboarding.currentState),
      compare: (a, b) => a.onboarding.currentState.localeCompare(b.onboarding.currentState),
      columnId: 'connected'
    }),
    createTableColumn<AxisCamera>({
      renderHeaderCell: () => 'Serial',
      renderCell: camera => <TableCellLayout>{camera.serial}</TableCellLayout>,
      columnId: 'serial',
      compare: (a, b) => a.serial.localeCompare(b.serial)
    }),
    createTableColumn<AxisCamera>({
      renderHeaderCell: () => '',
      renderCell: camera => (
        <TableCellLayout>
          {fetching.includes(camera.serial) ? (
            <Button>
              <Spinner size="tiny" />
            </Button>
          ) : (
            <Button onClick={() => onClickOffboard(camera)}>Offboard</Button>
          )}
        </TableCellLayout>
      ),
      columnId: 'offboard'
    })
  ];

  return (
    <DataGrid
      className={styles.table}
      items={allDevices.filter(
        d => selectedResourceGroup.id === '' || d.arn.includes(selectedResourceGroup.id)
      )}
      columns={columns}
      sortable
      defaultSortState={{sortColumn: 'serial', sortDirection: 'descending'}}
    >
      <DataGridHeader>
        <DataGridRow>
          {({renderHeaderCell}) => <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>}
        </DataGridRow>
      </DataGridHeader>
      <DataGridBody<DeviceInfo>>
        {({item, rowId}) => (
          <DataGridRow<DeviceInfo> key={rowId}>
            {({renderCell}) => <DataGridCell>{renderCell(item)}</DataGridCell>}
          </DataGridRow>
        )}
      </DataGridBody>
    </DataGrid>
  );
};
