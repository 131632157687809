import {
  makeStyles,
  tokens,
  Card,
  CardHeader,
  Input,
  Button,
  Field,
  CardFooter,
  Spinner
} from '@fluentui/react-components';
import useToasts from '../../hooks/useToasts';
import {useState} from 'react';
import {CreateResourceGroupDocument} from '../../api/__generated__/graphql';
import {useAppSelector} from '../../store/hooks';
import {usePopulatedTopbarValues} from '@axteams-one/populated-topbar';
import {useMutation} from 'urql';

const useStyles = makeStyles({
  card: {
    paddingLeft: tokens.spacingHorizontalXXL,
    paddingTop: tokens.spacingVerticalXXL,
    paddingBottom: tokens.spacingVerticalXXL
  },
  field: {
    width: '20em'
  }
});

const CreateResourceGroupCard = () => {
  const styles = useStyles();
  const {showSuccessToast, showErrorToast} = useToasts();
  const {organization} = usePopulatedTopbarValues();
  const {selectedResourceGroup} = useAppSelector(state => state.resourceGroupsSlice);
  const [name, setName] = useState('');
  const [{fetching}, createResourceGroup] = useMutation(CreateResourceGroupDocument);

  if (!organization) {
    return <></>;
  }

  const onClickCreate = () => {
    const parentArn =
      selectedResourceGroup.id === ''
        ? 'arn:organization:' + organization.id
        : 'arn:resource-group:' + organization.id + '/' + selectedResourceGroup.id;
    createResourceGroup({
      input: {
        description: 'Created in Data Insights Dashboard',
        name: name,
        parentArn: parentArn
      }
    }).then(result => {
      if (result.error) {
        showErrorToast('Failed to create resource group: ' + result.error.message);
      } else {
        showSuccessToast('Created resource group: ' + name);
      }
    });
  };

  return (
    <Card className={styles.card}>
      <CardHeader header={<b>Create resource group</b>} />
      <Field className={styles.field} label="Name" orientation="horizontal" required={true}>
        <Input onChange={e => setName(e.target.value)} />
      </Field>
      <CardFooter>
        {fetching ? (
          <Button>
            <Spinner size="tiny" />
          </Button>
        ) : (
          <Button onClick={onClickCreate}>Create</Button>
        )}
      </CardFooter>
    </Card>
  );
};

export default CreateResourceGroupCard;
