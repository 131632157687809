import {ACAPStatus, DeviceStatus, ScenarioId} from '../types';

export const nullToUndefined = <T>(obj: T | null): T | undefined => {
  if (obj === null) {
    return undefined;
  }
  return obj;
};

export const ACAPStatusEnum = (status: string): ACAPStatus => {
  if (status === 'NotInstalled') {
    return ACAPStatus.NotInstalled;
  } else if (status === 'Running') {
    return ACAPStatus.Running;
  } else if (status === 'Stopped') {
    return ACAPStatus.Stopped;
  }
  throw new Error('No enum variant for status: ' + status);
};

export const DeviceStatusFromString = (status: string): DeviceStatus => {
  if (status === 'Connected') {
    return DeviceStatus.Connected;
  } else if (status === 'Reachable') {
    return DeviceStatus.Reachable;
  } else if (status === 'Unreachable') {
    return DeviceStatus.Unreachable;
  } else if (status === 'Unknown') {
    return DeviceStatus.Unknown;
  } else if (status === 'OnboardingFailed') {
    return DeviceStatus.OnboardingFailed;
  } else if (status === 'OnboardingOngoing') {
    return DeviceStatus.OnboardingOngoing;
  }
  throw new Error('No enum variant for status: ' + status);
};

export const scenarioIdString = (scenarioId: ScenarioId): string => {
  switch (scenarioId) {
    case ScenarioId.A2DPCIn:
    case ScenarioId.A3DPCIn:
      return 'In';
    case ScenarioId.A2DPCOut:
    case ScenarioId.A3DPCOut:
      return 'Out';
  }
};
