import {
  ToastTitle,
  Toast,
  ToastTrigger,
  Link,
  ToastBody,
  useToastController
} from '@fluentui/react-components';
import {useCallback} from 'react';

export const defaultToasterId = 'toaster';

const useToasts = () => {
  const {dispatchToast} = useToastController(defaultToasterId);

  const showSuccessToast = useCallback(
    (message: string) => {
      dispatchToast(
        <Toast>
          <ToastTitle action={Dismiss()}>Success</ToastTitle>
          <ToastBody>{message}</ToastBody>
        </Toast>,
        {intent: 'success'}
      );
    },
    [dispatchToast]
  );

  const showErrorToast = useCallback(
    (message: string) => {
      dispatchToast(
        <Toast>
          <ToastTitle action={Dismiss()}>Unexpected Error</ToastTitle>
          <ToastBody>{message}</ToastBody>
        </Toast>,
        {intent: 'error'}
      );
    },
    [dispatchToast]
  );

  return {showSuccessToast, showErrorToast};
};

const Dismiss = () => (
  <ToastTrigger>
    <Link>Dismiss</Link>
  </ToastTrigger>
);

export default useToasts;
