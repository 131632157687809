import {
  Accordion,
  makeStyles,
  tokens,
  ProgressBar,
  AccordionItem,
  AccordionHeader
} from '@fluentui/react-components';
import {ACAPStatus, DeviceInfo, DeviceStatus} from '../../types';
import Panel from '../../components/Panel';
import {useCallback, useState} from 'react';
import PromiseQueue from '../../helpers/promiseQueue';
import {CameraDome28Regular} from '@fluentui/react-icons';
import AOAPanel from './AOAPanel';
import A3DPCPanel from './A3DPCPanel';
import {PanelEmptyView} from '@axiscommunications/fluent-empty-view';
import A2DPCPanel from './A2DPCPanel';

const useStyles = makeStyles({
  accordionHeader: {
    background: tokens.colorNeutralBackground2
  },
  accordionSpacing: {
    gap: tokens.spacingVerticalS,
    display: 'flex',
    flexDirection: 'column'
  },
  spinner: {
    justifyContent: 'center',
    paddingLeft: tokens.spacingHorizontalM
  },
  progressBarContainer: {
    minHeight: tokens.spacingVerticalXXS
  }
});

interface DataSourcePanelProps {
  readonly device?: DeviceInfo;
  readonly panelOpen: boolean;
  readonly onClose: () => void;
}

const getPanelTitle = (model?: string, serial?: string) => {
  if (model && serial) {
    return `${model} | ${serial}`;
  } else if (serial) {
    return serial;
  } else {
    return model;
  }
};

const tagRequestQueue = new PromiseQueue();

const DataSourcePanel = ({device, panelOpen, onClose}: DataSourcePanelProps) => {
  const styles = useStyles();
  const [pendingRequests, setPendingRequests] = useState<number>(0);

  const enqueueTagRequest = useCallback((promise: () => Promise<void>) => {
    tagRequestQueue.enqueue(promise, setPendingRequests);
  }, []);

  const A3DPCompatible = device?.model?.includes('P8815');
  const noDataSources = device?.acapStatuses.aoa === ACAPStatus.NotInstalled && !A3DPCompatible;

  return (
    <Panel
      size="large"
      title={device?.name}
      subtitle={getPanelTitle(device?.model, device?.serial)}
      titleIcon={<CameraDome28Regular />}
      isOpen={panelOpen}
      onClose={onClose}
      dataTestId="data-source-panel"
    >
      <div className={styles.progressBarContainer} data-testid={'progress-bar-' + pendingRequests}>
        {pendingRequests > 1 && <ProgressBar />}
      </div>
      {!device || device.status === undefined ? (
        <></>
      ) : device.status === DeviceStatus.Unreachable ? (
        <PanelEmptyView illustration="no-connection" title="Device is not reachable">
          Make sure that the device is connected and onboarded.
        </PanelEmptyView>
      ) : noDataSources ? (
        <PanelEmptyView illustration="data" title="No data sources">
          Make sure that the device has a compatible data source.
        </PanelEmptyView>
      ) : (
        <Accordion
          collapsible
          defaultOpenItems={
            device.acapStatuses.aoa === ACAPStatus.Stopped ? ['2', '3'] : ['1', '2', '3']
          }
          multiple
          className={styles.accordionSpacing}
        >
          {device.acapStatuses.aoa !== ACAPStatus.NotInstalled && (
            <AccordionItem value="1">
              <AccordionHeader size="large" className={styles.accordionHeader}>
                AXIS Object Analytics
              </AccordionHeader>
              <AOAPanel device={device} enqueueTagRequest={enqueueTagRequest} />
            </AccordionItem>
          )}
          {A3DPCompatible && (
            <AccordionItem value="2">
              <AccordionHeader size="large" className={styles.accordionHeader}>
                AXIS 3D People Counter
              </AccordionHeader>
              <A3DPCPanel device={device} enqueueTagRequest={enqueueTagRequest} />
            </AccordionItem>
          )}
          {device.acapStatuses.peopleCounter !== ACAPStatus.NotInstalled && (
            <AccordionItem value="3">
              <AccordionHeader size="large" className={styles.accordionHeader}>
                AXIS People Counter
              </AccordionHeader>
              <A2DPCPanel device={device} enqueueTagRequest={enqueueTagRequest} />
            </AccordionItem>
          )}
        </Accordion>
      )}
    </Panel>
  );
};

export default DataSourcePanel;
