import {Button, Card, Divider, makeStyles, Spinner, tokens} from '@fluentui/react-components';
import {usePopulatedTopbarValues} from '@axteams-one/populated-topbar';
import {useAppSelector} from '../../store/hooks';
import {getBasicDeviceInfo, getDeviceInfos} from '../../fetcher';
import {useCallback, useEffect, useState} from 'react';
import {DeviceInfo} from '../../types';
import {DeviceTable} from './DevicesTable';
import DataSourcePanel from './DataSourcePanel';
import PageContent from '../../components/PageContent';
import NoValidOrganization from '../../components/NoValidOrganization';
import {useSearchParams} from 'react-router-dom';
import useToasts from '../../hooks/useToasts';
import {ArrowClockwise20Regular} from '@fluentui/react-icons';

const useStyles = makeStyles({
  divider: {
    flexGrow: 0
  },
  spinner: {
    paddingTop: tokens.spacingVerticalXXL
  }
});

const openDID = (organizationId: string, resourceGroupId: string, traceparent: string | null) => {
  const searchParams = new URLSearchParams();
  searchParams.set('organizationId', organizationId);
  searchParams.set('resourceGroupId', resourceGroupId);
  if (traceparent) {
    searchParams.set('traceparent', traceparent);
  }
  window.open('/superset?' + searchParams.toString(), '_blank');
};

const Reporting = () => {
  const styles = useStyles();
  const {organization, loaded} = usePopulatedTopbarValues();
  const [devices, setDevices] = useState<DeviceInfo[]>();
  const [selectedSerial, setSelectedSerial] = useState<string>('');
  const [panelOpen, setPanelOpen] = useState<boolean>(false);
  const {selectedResourceGroup} = useAppSelector(state => state.resourceGroupsSlice);
  const [searchParams] = useSearchParams();
  const {showErrorToast} = useToasts();

  const getDevices = useCallback(() => {
    setDevices(undefined);
    if (!organization) {
      return;
    }
    getDeviceInfos({orgId: organization.id})
      .then(rsp => {
        if ('error' in rsp) {
          throw new Error(rsp.error);
        }
        setDevices(rsp);
        return rsp;
      })
      .then(rsp => {
        rsp.forEach(device => {
          if (device.model !== undefined) {
            return;
          }
          getBasicDeviceInfo(organization.arn, device.serial).then(info => {
            setDevices(devices =>
              devices === undefined
                ? undefined
                : devices.map(d => {
                    if (d.serial === device.serial) {
                      const model = d.model ? d.model : info.model;
                      return {...d, model};
                    }
                    return d;
                  })
            );
          });
        });
      })
      .catch(() => showErrorToast('Failed to get list of devices'));
  }, [organization, showErrorToast]);

  useEffect(() => {
    getDevices();
  }, [organization?.arn, showErrorToast, getDevices]);

  const selectedDevice = devices?.find(d => d.serial === selectedSerial);

  if (!organization) {
    return <NoValidOrganization loaded={loaded} />;
  }

  return (
    <PageContent testId="devices-tab" showResourceGroupTree title={selectedResourceGroup.name}>
      <Card size="small" appearance="subtle" orientation="horizontal">
        <Button
          data-testid="open-did-button"
          onClick={() =>
            openDID(organization.id, selectedResourceGroup.id, searchParams.get('traceparent'))
          }
          appearance="primary"
        >
          Open dashboard
        </Button>
        <Divider vertical className={styles.divider} />
        <Button
          disabled={devices === undefined}
          icon={<ArrowClockwise20Regular />}
          appearance="subtle"
          onClick={() => getDevices()}
        >
          Refresh
        </Button>
      </Card>
      {devices === undefined ? (
        <Spinner className={styles.spinner} size="large" />
      ) : (
        <>
          <DeviceTable
            devices={devices.filter(
              device =>
                selectedResourceGroup.id === '' || device.arn.includes(selectedResourceGroup.id)
            )}
            onDeviceSelected={serial => {
              setSelectedSerial(serial);
              setPanelOpen(true);
            }}
          />
          <DataSourcePanel
            device={selectedDevice}
            panelOpen={panelOpen}
            onClose={() => setPanelOpen(false)}
          />
        </>
      )}
    </PageContent>
  );
};

export default Reporting;
